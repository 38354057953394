import {Component} from "react";

export default class MyComponent extends Component {
  constructor(props) {
    super(props);
    
    // noinspection UnnecessaryReturnStatementJS - to fix IDE code completion
    return
  }

  setCurrentState(newState, callback = () => {}) {
    this.setState({
      current: newState
    }, callback);
  }

  delayedSetCurrentState(newState, delayMs, callback = () => {}) {
    setTimeout(this.setCurrentState.bind(this, newState, callback), delayMs);
  }

  setCurrentStateSequence(stateList, delayMs, callback = () => {}) {
    let stateStack = stateList.slice(0).reverse();
    this.setCurrentStateStack(stateStack, delayMs, callback);
  }

  setCurrentStateStack(stateStack, delayMs, callback = () => {}) {
    let currentState = stateStack.pop();
    if (stateStack.length > 0) {
      this.setCurrentState(currentState, () => {
        setTimeout(this.setCurrentStateStack.bind(this, stateStack, delayMs, callback), delayMs);
      });
    } else {
      this.setCurrentState(currentState, callback);
    }
  }

  inState = (state) => {
    if (Array.isArray(state)) {
      return state.includes(this.state.current)
    } else {
      return this.state.current === state
    }
  };

  inStates = this.inState;
}