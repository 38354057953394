import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import Sounds from '../../lib/Sounds'

import './Button.scss';

export default class Button extends Component {
  static propTypes = {
    'className': PropTypes.string,
    'onClick': PropTypes.func,

    'big': PropTypes.bool
  };

  static defaultProps = {
    'big': false,
  };

  render() {
    return (
      <div className={classNames("Button", this.props.className, {'big': this.props.big})}
        onClick={this.clicked}
      >
        { this.props.children }
      </div>
    );
  }

  clicked = () => {
    Sounds.click.play();
    this.props.onClick();
  }
}