import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import circleImg from './img/circle.svg';
import heart1Img from './img/heart-1.svg';
import heart2Img from './img/heart-2.svg';
import likeImg from './img/like.svg';
import lineImg from './img/line.svg';
import './AnimationCorrectExplosion.scss';


export default class AnimationCorrectExplosion extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,

    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div className={classNames("AnimationCorrectExplosion", {visible: this.props.visible})} style={this.getStyling()}>
        <img alt={'Bum'} className={`animation-correct-explosion animation-correct-explosion-circle`} src={circleImg} />
        <img alt={'Bum'} className={`animation-correct-explosion animation-correct-explosion-heart-1`} src={heart1Img} />
        {_.range(1, 3).map((index) => {
          return <img key={index} alt={'Bum'} className={`animation-correct-explosion animation-correct-explosion-heart-2-${index}`} src={heart2Img} />
        })}
        {_.range(1, 4).map((index) => {
          return <img key={index} alt={'Bum'} className={`animation-correct-explosion animation-correct-explosion-line-${index}`} src={lineImg} />
        })}
        {_.range(1, 4).map((index) => {
          return <img key={index} alt={'Bum'} className={`animation-correct-explosion animation-correct-explosion-like-${index}`} src={likeImg} />
        })}
      </div>
    )
  }

  getStyling = () => {
    return {
      left: `${this.props.x}px`,
      top: `${this.props.y}px`,
    }
  };
}