import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'babel-polyfill';
// import registerServiceWorker from './registerServiceWorker';
import WebFont from 'webfontloader';

// import {
//   EXERCISE_LIST, EXERCISE_LIST_QUESTIONS, EXERCISE_LIST_SHOOTING, EXERCISE_LIST_MEMORY, EXERCISE_LIST_CASUAL,
//   EXERCISE_LIST_ELEVATOR, EXERCISE_LIST_KPMG, , EXERCISE_LIST_HEALTHY_EATING,
// } from "./sprint/lists/Lists";

// ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<App parameters={{list: 'casual'}}/>, document.getElementById('root'));
// ReactDOM.render(<App parameters={{list: 'shooting'}}/>, document.getElementById('root'));
// ReactDOM.render(<App parameters={{list: 'questions'}}/>, document.getElementById('root'));
// ReactDOM.render(<App parameters={{list: 'memory'}}/>, document.getElementById('root'));
// ReactDOM.render(<App parameters={{list: 'elevator'}}/>, document.getElementById('root'));

// import { EXERCISES_KPMG } from "./sprint/lists/kpmg";
// ReactDOM.render(<App exercises={EXERCISES_KPMG}/>, document.getElementById('root'));

// ReactDOM.render(<App parameters={{list: 'healthy_eating'}}/>, document.getElementById('root'));

// import EXERCISES_RACHIPO from "./sprint/lists/rachipo";
// ReactDOM.render(<App exercises={EXERCISES_RACHIPO}/>, document.getElementById('root'));

// import EXERCISES_RELAGO from "./sprint/lists/relago";
// ReactDOM.render(<App exercises={EXERCISES_RELAGO}/>, document.getElementById('root'));

import EXERCISES_TEST from "./sprint/lists/test";
ReactDOM.render(<App exercises={EXERCISES_TEST}/>, document.getElementById('root'));

// registerServiceWorker();

WebFont.load({
  google: {
    families: ['Lato:400,700:latin,latin-ext', 'sans-serif']
  },
  custom: {
    families: ['Font Awesome 5 Icons:400,900', 'Font Awesome 5 Brands:400'],
    urls: ['//use.fontawesome.com/releases/v5.1.0/css/all.css']
  },
});
