import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

import {MemoryAnswerCard} from "../components/AnswerCard";
import PointsBar from "../components/PointsBar";
import Animation from "../components/Animation";

import './css/MemoryExercise.css'
import Card, {ButtonCard} from "../components/Card";

export const EXAMPLE_CONFIG = [{
  "name": "Memory",
  "exercises": [{
    "id": "memory",
    "type": "memory",
    "parameters": {
      "helpText": "Kliknij na kartę, aby ją odwrócić. Odwróć dwie karty z identycznym obrazkiem, aby zdobyć punkt. Masz na to ćwiczenie 90 sek. Zegar startuje wraz z odkryciem pierwszej karty."
    }
  }]
}]

const ANIMATION_SPEED = 1000;
const TIME_LIMIT = 120;

class MemoryExercise extends Component {

  static propTypes = {
    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      answers: [],
      lastKey: null,
      lastAnswer: null,

      clockRunning: false,
      finished: false,
      timeRanOut: false,
      points: 0,
      playing: true,

      changed: 0,
    };

    for (let i = 1; i <= 10; i++) {
      for (let j = 1; j <= 2; j++) {
        let icon = {
          id: i,
          visible: true,
          side: 'front',
        };
        this.state.answers.push(icon);
      }
    }

    this.state.answers = _.shuffle(this.state.answers);

    this.answerChosen = this.answerChosen.bind(this);
    this.goNext = this.goNext.bind(this);
    this._goNext = this._goNext.bind(this);
  }

  answerChosen(answer, key) {
    if (!this.state.playing) {
      return;
    }

    let lastAnswer = this.state.lastAnswer;
    let lastKey = this.state.lastKey;
    let points = this.state.points;
    let playing = true;
    let flip = false;
    let finished = false;
    let clockRunning = true;

    if (this.state.lastAnswer) {
      if (answer.id === lastAnswer.id) {
        new Audio('/sounds/success.mp3').play();
        points++;

        if (points === this.state.answers.length / 2) {
          finished = true;
          clockRunning = false;
        }
      } else {
        new Audio('/sounds/error.mp3').play();
        flip = true;
      }
      lastAnswer = null;
    } else {
      new Audio('/sounds/click.mp3').play();
      lastAnswer = answer;
      lastKey = key;
    }

    this.setState((prevState) => {
      prevState.answers[key].side = 'back';
      return {
        finished: finished,
        playing: playing,
        lastKey: lastKey,
        lastAnswer: lastAnswer,
        points: points,
        answers: prevState.answers,
        clockRunning: clockRunning,
      }
    });

    if (flip) {
      setTimeout(() => {
        this.setState((prevState) => {
          prevState.answers[key].side = 'front';
          prevState.answers[lastKey].side = 'front';
          return {
            playing: true,
            answers: prevState.answers,
          }
        });
      }, 1000);
    }
  }

  timeRanOut = () => {
    this.setState({
      playing: false,
      finished: true,
      timeRanOut: true,
      clockRunning: false,
    });
  };

  goNext() {
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  }

  _goNext() {
    this.props.goNextAction(this.state.points);
  }

  render() {
    let answers;
    
    answers = this.state.answers.map((answer, index) => {
      return <MemoryAnswerCard answer={answer} side={answer.side} visible={answer.visible}
                               iconId={answer.id} clickCallback={this.answerChosen}
                               index={index} key={index} />
    });
    

    return (
      <Animation type="fade" active={this.state.visible}>
        <div className="MemoryExercise">
          <PointsBar
            points={this.state.points} maxPoints={10}
            timeLimit={TIME_LIMIT} clockRunning={this.state.clockRunning} onTimeRanOut={this.timeRanOut}
            instruction="Odkryj pary identycznych zdjęć"
          />
          <div className={classNames('answers-container', {'game-finished': this.state.timeRanOut})}>
            { answers }
          </div>
          <Animation type="fade" active={this.state.timeRanOut}>
            <div className={'time-finished'}>
              <Card>
                <h1>Koniec czasu!</h1>
              </Card>
            </div>
          </Animation>
          <Animation type="fade" active={this.state.finished}>
            <div className="buttons-container">
              <ButtonCard onClick={this.goNext}>
                Przejdź dalej
              </ButtonCard>
            </div>
          </Animation>
        </div>
      </Animation>
    );
  }
}

export default MemoryExercise;