import { Howl } from 'howler'

class Sound {
  constructor(sources) {
    this.sound = new Howl({
      src: sources
    })
  }

  play() {
    this.sound.play(undefined, undefined)
  }
}

export default class Sounds {
  static error = new Sound(['/sounds/error.mp3'])
  static success = new Sound(['/sounds/success.mp3'])
  static click = new Sound(['/sounds/click.mp3'])

  static short_bell = new Sound(['/sounds/short_bell.mp3'])
  static long_bell = new Sound(['/sounds/long_bell.mp3'])
}

