import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/SummaryScreen.css';
import Card from "../components/Card";
import Animation from "../components/Animation";

const ANIMATION_SPEED = 1000;

export default class SummaryScreen extends Component {
  static propTypes = {
    data: PropTypes.array,
    fullPoints: PropTypes.number,
    maxPoints: PropTypes.number,
    canRepeatExercises: PropTypes.bool,

    replayAction: PropTypes.func,
    nextAction: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    fullPoints: 0,
    maxPoints: 0,
    canRepeatExercises: true,

    replayAction: () => {},
    nextAction: () => {},
  };

  fullPoints = 0
  maxPoints = 0

  constructor(props) {
    super(props);

    this.fullPoints = Object.values(props.data).reduce((previous, current) => {
      return previous + parseInt(current.points, 10)
    }, 0)
    this.maxPoints = Object.values(props.data).reduce((previous, current) => {
      return previous + parseInt(current.maxPoints, 10)
    }, 0)

    this.state = {
      visible: true,
    };
  }

  goNext = (action, levelNumber) => {
    if (this.state.visible) {
      new Audio('/sounds/click.mp3').play();
      this.setState({
        visible: false,
      });

      if (action === 'next')
        setTimeout(this.props.nextAction, ANIMATION_SPEED);
      else if (action === 'replay')
        setTimeout(this.props.replayAction, ANIMATION_SPEED, levelNumber);
    }
  };

  render() {
    let summary = this.props.data.map((levelInfo, index) => {
      return (
        <div className={'summary-row'} key={index}>
          <div className={(this.props.canRepeatExercises ? 'pure-u-2-3' : 'pure-u-1-1') + ' score-container'}>
            <div className="vertical-helper" />
            <Card className={'score'}>
              <div className="pure-u-2-3 name">
                <p>{ levelInfo.name }</p>
              </div>
              <div className="pure-u-1-3 points">
                <p>{ levelInfo.points }{levelInfo.maxPoints > 0 ? ' / ' + levelInfo.maxPoints : ''}</p>
              </div>
            </Card>
          </div>
          {this.props.canRepeatExercises &&
          <div className={'pure-u-1-3 buttons-container'}>
            <div className="vertical-helper"/>
            <Card classes={'button'} onClick={this.goNext.bind(this, 'replay', levelInfo.levelNumber)}>
              <p>Popraw wynik</p>
            </Card>
          </div>
          }
        </div>
      );
    });

    return (
      <Animation type={Animation.TYPES.fade} active={this.state.visible}>
        <div className="SummaryScreen">
          <Card className={'header'}>
            <h1>Podsumowanie</h1>
          </Card>
          <div className="summary-container pure-g">
            {summary}
            {this.maxPoints > 0 &&
            <div className="summary-row">
              <div className="pure-u-1-1 score-container full">
                <Card className={'score'}>
                  <div className="pure-u-2-3 name">
                    <p>Razem</p>
                  </div>
                  <div className="pure-u-1-3 points">
                    <p>{this.fullPoints} / {this.maxPoints}</p>
                  </div>
                </Card>
              </div>
            </div>
            }
          </div>
          <div className="pure-g buttons-container">
            <div className="pure-u-1-1 center">
              <Card classes="next-button" onClick={this.goNext.bind(this, 'next')}>
                <p>Zakończ</p>
              </Card>
            </div>
          </div>
        </div>
      </Animation>
    );
  }
}
