import React, {Component} from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import './Card.scss';

export default class Card extends Component {
  static COLORS = {
    BRIGHT: 'bright',
    MAIN: 'main',
    ACCENT: 'accent',
  };

  static propTypes = {
    className: propTypes.string,
    color: propTypes.string,
  };

  static defaultProps = {
    color: Card.COLORS.MAIN,
  };

  render() {
    return (
      <div className={classNames("Card", this.props.color, this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}