import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Card from "../components/Card";

import './css/LoginScreen.scss'
import Animation from "../components/Animation";

const ANIMATION_SPEED = 1000;
const USE_REMOTE_LOGIN = true;
const LOCAL_USER = 'test';
const LOCAL_PASS = 'test';

const USERNAME_VALIDATION_RE = /^[A-Za-z0-9_ ęóąśłżźćńĘÓĄŚŁŻŹĆŃ]+$/

class LoginScreen extends Component {
  static propTypes = {
    'loginAction': PropTypes.func,
    'onlyUsernameNeeded': PropTypes.bool,
    'serverAddress': PropTypes.string,
  };

  static defaultProps = {
    'onlyUsernameNeeded': false
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      submitDisabled: false,

      message: '',
      messageType: 'error',

      username: '',
      password: '',
    };

    this.loggedIn = this.loggedIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  _showLogin = () => {
    this.setState({
      visible: true,
      submitDisabled: false,
    })
  }

  _createUser(username, showMessages, onSuccess) {
    return this._checkLogin(this.props.serverAddress + 'auth/create', {
      username: username,
    }, showMessages, onSuccess)
  }

  _checkLoginWithToken(username, token, showMessages, onSuccess) {
    return this._checkLogin(this.props.serverAddress + 'auth/token', {
      username: username,
      token: token,
    }, showMessages, onSuccess)
  }

  _checkLogin = (address, data, showMessages = true, onSuccess = () => {}) => {
    axios({
      method: 'POST',
      url: address,
      data: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      if (result.data['status'] === 'Success') {
        if (showMessages) {
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
        }

        onSuccess(result.data['token'])
      } else {
        this._showLogin()
        if (showMessages) {
          this.setState({
            message: 'Nieprawidłowe dane logowania',
            messageType: 'error',

          })
        }
      }
    }).catch((error) => {
      this._showLogin()
      if (showMessages) {
        this.setState({
          message: 'Nieprawidłowe dane logowania',
          messageType: 'error',
        })
      }
    })
  }

  componentDidMount() {
    if (this.props.onlyUsernameNeeded) {
      let username = window.localStorage.getItem('username');
      let token = window.localStorage.getItem('token');
      if (!username || !token) {
        this._showLogin()
      } else {
        this._checkLoginWithToken(username, token, false, (token) => {
          // On success
          window.localStorage.setItem('token', token);
          this.loggedIn(username);
        })
      }
    } else if (USE_REMOTE_LOGIN) {
      let token = window.localStorage.getItem('token');
      if (token) {
        axios({
          method: 'POST',
          url: this.props.serverAddress + 'api/check-login',
          data: {},
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        }).then((result) => {
          if (!(result.data['message'] === 'Unauthenticated.')
            && (result.data['status'] === 'Success')) {
            this.loggedIn(result.data['username']);
          } else {
            this._showLogin()
          }
        }).catch((error) => {
          this._showLogin()
        });
      } else {
        this._showLogin()
      }
    }
  }

  loggedIn(username) {
    this.setState({
      'visible': false,
    });

    setTimeout(() => {this.props.loginAction(username)}, ANIMATION_SPEED);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  static generateToken(username) {
    return username + '#' + Math.random().toString(36).substr(2, 8)
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({
      submitDisabled: true,
    });
    const { username, password } = this.state;

    if (!USERNAME_VALIDATION_RE.test(username)) {
      this.setState({
        message: 'Wpisz tylko litery i liczby jako nazwę użytkownika.',
        messageType: 'error',
        submitDisabled: false
      })
    } else if (username.length < 3) {
      this.setState({
        message: 'Nazwa użytkownika musi mieć co najmniej 3 znaki.',
        messageType: 'error',
        submitDisabled: false
      })
    } else if (this.props.onlyUsernameNeeded) {
      let usernameWithToken = LoginScreen.generateToken(username)
      this._createUser(usernameWithToken, true, (token) => {
        // onSuccess
        window.localStorage.setItem('username', usernameWithToken);
        window.localStorage.setItem('token', token);

        setTimeout(() => {this.loggedIn(usernameWithToken)}, ANIMATION_SPEED);
      })
    } else if (!USE_REMOTE_LOGIN) {
      setTimeout(() => {
        if (username === LOCAL_USER && password === LOCAL_PASS) {
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
          setTimeout(() => {
            this.loggedIn(username)
          }, ANIMATION_SPEED);
        } else {
          this.setState({
            message: 'Nieprawidłowe dane logowania',
            messageType: 'error',
            submitDisabled: false,
          });
        }
      }, 1000);
    } else {
      axios({
        method: 'POST',
        url: this.props.serverAddress + 'api/login',
        data: {
          'username': username,
          'password': password
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then((result) => {
        if (result.data['status'] === 'success') {
          window.localStorage.setItem('username', username);
          window.localStorage.setItem('token', result.data['token']);
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
          setTimeout(() => {this.loggedIn(username)}, ANIMATION_SPEED);
        } else {
          this.setState({
            message: 'Nieprawidłowe dane logowania',
            messageType: 'error',
            submitDisabled: false,
          });
        }
      }).catch((error) => {
        this.setState({
          message: 'Wystąpił problem z połączeniem - spróbuj jeszcze raz',
          messageType: 'error',
          submitDisabled: false,
        });
      });
    }
  }

  render() {
    return (
      <Animation active={this.state.visible} type={Animation.TYPES.fade}>
        <div className="LoginScreen">
          <div className="pure-g title-container">
            <div className="pure-u-1-5"/>
            <div className="pure-u-3-5">
              <Card className={'login'}>
                <div className="logotype">
                  <img src="/images/logotype-blue.png" alt="SprinTECH Learning" />
                </div>
                <div className="login-form">
                  <form className="pure-form pure-form-aligned"
                        onSubmit={this.handleFormSubmit}
                  >
                    <fieldset>
                      <div className="pure-control-group">
                        <label htmlFor="name">Użytkownik</label>
                        <input name="username" type="text" placeholder="Nazwa użytkownika"
                               value={this.state.username}
                               onChange={this.handleInputChange}
                        />
                      </div>

                      {! this.props.onlyUsernameNeeded &&
                        <div className="pure-control-group">
                          <label htmlFor="password">Hasło</label>
                          <input name="password" type="password" placeholder="Hasło"
                                 value={this.state.password}
                                 onChange={this.handleInputChange}
                          />
                        </div>
                      }
                      <div className={'message ' + this.state.messageType}>
                        {this.state.message}
                      </div>
                      <button type="submit" className="pure-button pure-button-primary login-button" disabled={this.state.submitDisabled}>{this.state.submitDisabled ? <span><i className="fas fa-spinner fa-spin"/> Loguję...</span> : <span>Zaloguj</span>}</button>
                    </fieldset>
                  </form>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Animation>
    );
  }
}

export default LoginScreen;
