import React from "react"
import _ from "lodash";
import Velocity from "velocity-animate";

import MyComponent from "../../base/MyComponent";
import Sounds from '../../lib/Sounds'

import PointsBar from "../../components/PointsBar/PointsBar";
import Card from "../../components/Card/Card";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import FeedbackCard from "../../components/FeedbackCard/FeedbackCard";

import "./TrainExercise.scss"

export const TRAIN_POINTS_PER_QUESTION = 4;

const ANIMATION_SPEED = 1000;
const TRAIN_POINTS_PER_QUESTION_SECOND_TIME = 2;
const TIME_BETWEEN_STATIONS = 3;
const TIME_FOR_ANSWER = 8;
const TIME_FOR_COOLDOWN = 1;
const TIME_FOR_FEEDBACK = 5;
const TIME_LIMIT_MULTIPLIER = TIME_BETWEEN_STATIONS + TIME_FOR_ANSWER + TIME_FOR_COOLDOWN;

const STATES = {
  STARTING: 0,
  DRIVING_TO_NEXT_QUESTION: 1,
  ON_THE_STATION: 2,
  STATION_CHOSEN: 3,
  QUESTION_HIDING: 4,
  FINISHING: 5,
  FINISHED: 6,
};

const QUESTION_VISIBLE_STATES = [
  STATES.DRIVING_TO_NEXT_QUESTION,
  STATES.ON_THE_STATION,
  STATES.STATION_CHOSEN,
];


export default class TrainExercise extends MyComponent {
  timeout = null;

  chosenAnswerIds = [];

  maxPoints = 0;
  timeLimit = 0;

  constructor(props) {
    super(props);

    this.state = {
      current: STATES.STARTING,

      points: 0,
      maxPoints: props.questions.length * TRAIN_POINTS_PER_QUESTION,
      secondPass: false,

      clockId: 0,

      currentQuestionIndex: -1,
      currentAnswerIndex: -1,
      answers: [],
    };

    this.maxPoints = props.questions.length * TRAIN_POINTS_PER_QUESTION;

    this.stationRef = React.createRef();
    this.railsRef = React.createRef();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    let answer = this.getCurrentAnswer();

    return (
        <div className="TrainExercise">
          <PointsBar visible={!this.inState(STATES.FINISHED)}
            points={this.state.points} maxPoints={this.maxPoints}
            timeLimit={this.timeLimit} clockRunning={this.inStates([STATES.DRIVING_TO_NEXT_QUESTION, STATES.ON_THE_STATION])} clockId={this.state.currentQuestionIndex}
            onTimeRanOut={this.timeRanOut} clockWarningSeconds={Math.ceil(this.timeLimit / 5)}
          />
          <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut}>
            <Button onClick={this.startGame} big>
              Rozpocznij
            </Button>
          </AnimatedElement>
          <AnimatedElement visible={this.inStates(QUESTION_VISIBLE_STATES)} animation={AnimatedElement.AnimationTypes.slideLeft} durationMs={ANIMATION_SPEED}>
            <InstructionCard
              countType="Pytanie" countCurrent={this.currentQuestionCount()} countMax={this.props.questions.length}
              mainText={this.currentQuestionContent()}
            />
          </AnimatedElement>
          <FeedbackCard key="feedback" visible={this.inState(STATES.STATION_CHOSEN)}
            content={answer.parameters ? answer.parameters.feedback : ''} successful={answer.correct}/>
          <AnimatedElement visible={this.inStates(QUESTION_VISIBLE_STATES)}>
            <div className="train-container">
              <div className="animated-element station" ref={this.stationRef} />
              <div className="animated-element train" />
              <div className="animated-element rails" ref={this.railsRef} />
            </div>
          </AnimatedElement>
          <AnimatedElement visible={this.inStates([STATES.ON_THE_STATION, STATES.STATION_CHOSEN])}>
            <div className="answer-container">
              <Card className="answer" color={Card.COLORS.BRIGHT}>
                <div>
                  <p className='answer-count'>Stacja {this.state.currentAnswerIndex + 1} z { this.state.answers.length }</p>
                  <p className='answer-content'>{ answer.content }</p>
                </div>
              </Card>
              <Button onClick={this.answerChosen}>
                Wysiadam
              </Button>
            </div>
          </AnimatedElement>
          <AnimatedElement visible={this.inStates(STATES.FINISHING)} animation={AnimatedElement.AnimationTypes.popOut}>
            <Container autoHeight>
              <Button onClick={this.goNext} big>
                Przejdź dalej
              </Button>
            </Container>
          </AnimatedElement>
        </div>
    )
  }

  startGame = () => {
    if (this.inState(STATES.STARTING)) {
      this.nextQuestion();
    }
  };

  currentQuestionCount = () => {
    return this.state.currentQuestionIndex + 1;
  };

  currentQuestionContent = () => {
    let question = this.getCurrentQuestion();
    if (question) {
      return question.content;
    } else {
      return '';
    }
  };

  getCurrentQuestion = () => {
    return this.props.questions[this.state.currentQuestionIndex];
  };

  showAnswer = () => {
    this.setState((prevState) => {
      let secondPass = prevState.secondPass;
      let index = prevState.currentAnswerIndex + 1;
      if (index >= this.state.answers.length) {
        index = 0;
        secondPass = true;
      }

      return {
        currentAnswerIndex: index,
        secondPass,
      }
    }, () => {this.setCurrentState(STATES.ON_THE_STATION)});

    this.timeout = setTimeout(this.nextStation, TIME_FOR_ANSWER * 1000)
  };

  nextStation = () => {
    this.setCurrentState(STATES.DRIVING_TO_NEXT_QUESTION, () => {
      this.timeout = setTimeout(this.moveRails.bind(this, TIME_BETWEEN_STATIONS * 1000, this.showAnswer), TIME_FOR_COOLDOWN * 1000);
    });
  };

  timeRanOut = () => {
    clearTimeout(this.timeout);

    Sounds.error.play();

    this.timeout = setTimeout(this.nextQuestion, 1000);
  };

  nextQuestion = () => {
    this.setState((prevState) => {
      let newQuestionIndex = prevState.currentQuestionIndex
      if (prevState.currentQuestionIndex < this.props.questions.length - 1) {
        newQuestionIndex++;
        this.timeLimit = this.props.questions[newQuestionIndex].answers.length * TIME_LIMIT_MULTIPLIER * 2;
        this.timeout = setTimeout(this.questionChanged, 1000)
      } else {
        this.timeout = setTimeout(this.gameFinished, 1000)
      }

      return {
        currentQuestionIndex: newQuestionIndex,
        secondPass: false,
      }
    }, () => {this.setCurrentState(STATES.QUESTION_HIDING)})
  };

  gameFinished = () => {
    this.setCurrentState(STATES.FINISHING);
  };

  questionChanged = () => {
    let question = this.props.questions[this.state.currentQuestionIndex];

    this.setState({
      currentAnswerIndex: -1,
      answers: _.shuffle(question.answers),
    }, () => {this.setCurrentState(STATES.DRIVING_TO_NEXT_QUESTION)});
    this.nextStation()
  };

  getCurrentAnswer = () => {
    if (this.state.currentAnswerIndex < 0)
      return '';
    else
      return this.state.answers[this.state.currentAnswerIndex]
  };

  answerChosen = () => {
    clearTimeout(this.timeout);

    this.setState((prevState) => {
      let pointsChange = 0;
      let answer = this.getCurrentAnswer();
      this.chosenAnswerIds.push(answer.id);

      if (answer.correct) {
        Sounds.success.play();
        pointsChange = prevState.secondPass ? TRAIN_POINTS_PER_QUESTION_SECOND_TIME : TRAIN_POINTS_PER_QUESTION
      } else {
        Sounds.error.play()
      }

      this.timeout = setTimeout(this.nextQuestion, TIME_FOR_FEEDBACK * 1000)

      return {
        points: prevState.points + pointsChange,
      }
    }, () => {this.setCurrentState(STATES.STATION_CHOSEN)})
  };

  moveRails = (duration, onArrival) => {
    this.moveFromStation(duration / 2);
    this.moveToStation(duration / 2);
    this.timeout = setTimeout(onArrival, duration);
  };

  moveToStation = (duration) => {
    Velocity(
      this.railsRef.current, {
        backgroundPositionX: ['60em', '150em'],
      }, {
        duration: duration,
        easing: "easeOutSine",
      }
    );
    Velocity(
      this.stationRef.current, {
        backgroundPositionX: ['60em', '150em'],
      }, {
        duration: duration,
        easing: "easeOutSine",
      }
    )
  };

  moveFromStation = (duration) => {
    Velocity(
      this.railsRef.current, {
        backgroundPositionX: ['-30em', '60em'],
      }, {
        duration: duration,
        easing: "easeInSine",
      }
    );
    Velocity(
      this.stationRef.current, {
        backgroundPositionX: ['-30em', '60em'],
      }, {
        duration: duration,
        easing: "easeInSine",
      }
    )
  };
  
  goNext = () => {
    if (this.inState(STATES.FINISHING)) {
      this.setCurrentState(STATES.FINISHED, () => {
        setTimeout(this._goNext, ANIMATION_SPEED)
      });
    }
  };

  _goNext = () => {
    this.props.goNextAction({
      points: this.state.points,
      other: {
        chosenAnswerIds: this.chosenAnswerIds,
      }
    })
  };
}