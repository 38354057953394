import React, {Component} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Overlay from "../Overlay/Overlay";
import AnimatedElement from "../AnimatedElement/AnimatedElement";

import './FeedbackCard.scss';

export default class FeedbackCard extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    content: PropTypes.string.isRequired,
    successful: PropTypes.bool,
  };

  static defaultProps = {
    successful: false,
  };

  render () {
    return (
      <AnimatedElement visible={this.props.visible} className="FeedbackCard" durationMs={1000} zIndex={100}>
        <Overlay zIndex={100} />
        <div
          className={classNames(
            "feedback",
            {
              "success": this.props.successful,
            })
          }
        >
            <p>{this.props.content}</p>
        </div>
      </AnimatedElement>
    )
  }
}